import React from "react";

const Page = () => (
  <div class="note-wrapper">
    <h1>Terms of Service</h1>
    <p>
      By using the App, you accept and agree to these Terms and Conditions.
      Therefore, it is important that you carefully read through these Terms. If
      you do not consent to these Terms, you should not use the App.
    </p>
    <br />
    <h2>Intellectual Property Rights</h2>
    <p>
      All rights in and to the Services, including any trademarks, service
      marks, trade names and copyrighted content (collectively “Intellectual
      Property”) presented within the App are the property of Fang Xingchen
      and/or third parties. You agree not to use Intellectual Property for any
      other purposes except for your use of the Service unless required
      otherwise by applicable mandatory law.
    </p>
    <br />
    <h2>My Time Pro Subscription</h2>
    <p>
      This section only applies when you subscribe to My Time Pro. By paying the
      subscription fee, you get access to My Time Pro Features during the time
      your subscription is valid, subject to these Terms.
    </p>
    <br />
    <p>
      We currently offer a free 7-day trial for the subscription, ensuring that
      you pay after the trial is satisfactory. At the beginning of the trial
      period, you may need to enter relevant payment information for this
      service. but you will not be charged until the last day of the trial. if
      you do not cancel the subscription, it will be considered as continuing to
      use and the debit will occur automatically.
    </p>
    <br />
    <p>
      After the trial period expires, the current subscription fee will not be
      refunded. Be sure to cancel the service yourself before the trial ends.
    </p>
    <br />
    <p>
      We reserve the right to change the subscription fees from time to time.
    </p>
    <br />
    <p>
      All payments handled by Apple, such as in-app purchases, can only be
      refunded by Apple and with Apple’s consent. If you would like to contact
      Apple, please
      <a href="http://support.apple.com/kb/ht4098">click here</a>
    </p>
    <br />
    <h2>Changes to This Terms and Conditions</h2>
    <p>
      We may update our Terms and Conditions from time to time. Thus, you are
      advised to review this page periodically for any changes. We will notify
      you of any changes by posting the new Terms and Conditions on this page.
      These changes are effective immediately after they are posted on this
      page.
    </p>
    <br />
    <h2>Contact Us</h2>
    <p>
      If you have any questions or suggestions about our Terms and Conditions,
      do not hesitate to contact us at
      <a href="mailto:support@fxc.ninja">support@fxc.ninja</a>.
    </p>
  </div>
);

export default Page;
